import { TChangePassword, TypeServerList, TypeSystemTypeList, TToken } from "../@types/DataTypes";
import { storage } from "util/Storage";
import { TUser } from "./AuthenticationStorage";

export class HttpError extends Error {
  statusCode: number;
  constructor(response: Response) {
    super(response.statusText);
    this.statusCode = response.status;
  }
}

const checkStatus = (response: Response): Response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new HttpError(response);
  console.error(error); // eslint-disable-line no-console
  console.log(response);
  throw error;
}

function parseJSON<T>(response: Response): Promise<T> {
  return response.json();
}

// -------------------------------------------------------------------------

const login = (username: string, password: string): Promise<TToken> => {
  const form = new FormData();
  form.append("username", username);
  form.append("password", password);

  return fetch(`/token`, {
    method: 'POST', 
    body: form
  })
    .then(checkStatus)
    .then(parseJSON<TToken>);
};

const getUser = (): Promise<TUser> | undefined => {
  const { accessToken, isAuthenticated } = storage;

  if (!isAuthenticated) {
    console.log('aborting fetch of user - not authenticated');
    return;
  }

  return fetch(`/api/users/me`, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
  })
    .then(checkStatus)
    .then(parseJSON<TUser>);
};

const changePassword = (data: TChangePassword): Promise<unknown> | undefined => {
  const { accessToken, isAuthenticated } = storage;

  if (!isAuthenticated) {
    console.log('aborting changing passsword - not authenticated');
    return;
  }

  return fetch(`/api/users/change-pw`, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      "content-type": 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  })
    .then(checkStatus)
    .then(parseJSON);
};

// -------------------------------------------------------------------------


const getServerList = (): Promise<TypeServerList> | undefined => {
  const { accessToken, isAuthenticated } = storage;

  if (!isAuthenticated) {
    console.log('aborting fetch of server list - not authenticated');
    return;
  }

  return fetch(`/api/server-list`, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
  })
    .then(checkStatus)
    .then(parseJSON<TypeServerList>);
}

const getSystemTypeList = (): Promise<TypeSystemTypeList> | undefined => {
  const { accessToken, isAuthenticated } = storage;

  if (!isAuthenticated) {
    console.log('aborting fetch of system-type list - not authenticated');
    return;
  }

  return fetch(`/api/system-type-list`, {
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then(checkStatus)
    .then(parseJSON<TypeSystemTypeList>);
}

const Client = {
  // isOnline,
  getUser,
  login,
  changePassword,
  getServerList,
  getSystemTypeList
};

export default Client;
