// based on: https://github.com/joonas-yoon/fastapi-react-oauth2/tree/main

const TOKEN_NAME = 'access_token';

class AuthenticationStorage {
  private _access_token: string | null = globalThis.sessionStorage.getItem(TOKEN_NAME);

  get isAuthenticated (): boolean {
    return this._access_token !== null;
  }

  get accessToken (): string | null {
    return this._access_token;
  }

  clear(): void {
    globalThis.sessionStorage.clear();
    this._access_token = null;
  }

  updateToken(new_token: string | null): void {
    if (new_token !== null) {
      this._access_token = new_token;
      globalThis.sessionStorage.setItem(TOKEN_NAME, new_token);
    } else {
      globalThis.sessionStorage.removeItem(TOKEN_NAME);
      this._access_token = null;
    }
  }
}

export const storage = new AuthenticationStorage();