import React, { useCallback, useMemo } from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import { getToRoute, RouteID, useCurrentRoutePathHook } from '../navigation/Navigation';
import Logo from '../res/smart/zactrack_logo.png';
import { useAuthentication } from 'remote/AuthenticationStorage';
import { LoginAvatar } from './LoginAvatar';

const getLogo = (): string => {
  return Logo as string;
}

const StyledLogo = styled('img')(({ theme }) => ({
  display: 'inline',
  position: 'relative',
  [theme.breakpoints.up('xs')]: {
    height: '32px'
  },
  [theme.breakpoints.up('sm')]: {
    height: '48px'
  },
  bottom: '4%',
  float: 'left',
  cursor: 'pointer',
}));

const StyledHorizontalPlaceholder = styled(Box)({
  flex: 1
});

const horizontalPlaceholder = <StyledHorizontalPlaceholder />;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  paddingTop: '5px',
  paddingBottom: '5px',
  backgroundColor: theme.zOptions.header.backgroundColor,
  boxShadow: '0px 3px 3px 3px ' + theme.zOptions.header.backgroundColor,
  zIndex: 30,
  position: 'relative',
  flex: '0 1 auto'
}));

const StyledToolbar = styled(Toolbar)(() => ({
  minHeight: '0px !important'
}));

const StyledNavigationButton = styled(Button, {
  shouldForwardProp: (propFullName) => propFullName !== 'selected'
})<{ selected?: boolean }>(({ theme, selected }) => ({
  textTransform: 'none',
  fontSize: '1.3rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: selected ? theme.palette.secondary.main : 'white',
  [theme.breakpoints.up('xs')]: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '15px',
    marginRight: '15px'
  },
  ':hover': {
    backgroundColor: theme.zOptions.util.background.highlight,
    cursor: 'pointer',
  }
}));

const Header = (): JSX.Element => {
  // const theme = useTheme();
  const navigationHook = useNavigate();
  const currentRoutePathIDs = useCurrentRoutePathHook();
  const { isAuthenticated } = useAuthentication();

  const handleTabChange = useCallback((
    mouseEvent: React.MouseEvent<HTMLElement, MouseEvent>,
    route: RouteID
  ): void => {
    if (mouseEvent.type === 'keydown') {
      // Dominik Völkel: 12-01-2023
      // ZWC-27
      // Keyboard events can be triggered on buttons (navigate with tab and then click enter),
      // or you can press esc or any other button. This lands in onClick handler,
      // but the typescript event type is specified to be onclick, which is MouseEvent and is missing the fields for keyboard events.
      // The event type is there though so we can cast to it and use the fields as normal.
      // (to unknown first is necessary to prevent the compiler from complaining that it might be a misscast)

      const keyEvent = mouseEvent as unknown as KeyboardEvent;
      if (keyEvent.key === 'Tab' || keyEvent.key === 'Shift') {
        return;
      }
    }

    navigationHook(getToRoute(route));
  }, [navigationHook]);

  const usedLogo = useMemo(() => getLogo(), []);

  const displayedLogo = useMemo(
    () => (
      <StyledLogo src={usedLogo} alt='zactrack Logo' onClick={(evt): void => handleTabChange(evt, RouteID.Server)}/>
    ),
    [usedLogo, handleTabChange]
  );

  return (
    <StyledAppBar position='static'>
      <StyledToolbar>
        {displayedLogo}
        {horizontalPlaceholder}
        {isAuthenticated && (
          <>
            <StyledNavigationButton
              color='primary'
              variant='text'
              onClick={(evt): void => handleTabChange(evt, RouteID.Server)}
              selected={currentRoutePathIDs.includes(RouteID.Server)}
            >
              Server
            </StyledNavigationButton>
              {/* <StyledNavigationButton
                color='primary'
                variant='text'
                onClick={(evt): void =>
                  handleTabChange(evt, RouteID.Admin)
                }
                selected={currentRoutePathIDs.includes(RouteID.Admin)}
              >
                Admin
              </StyledNavigationButton> */}
            {horizontalPlaceholder}
            <LoginAvatar />
          </>
        )}

      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Header;
