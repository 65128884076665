import {
  Box,
  Divider,
  List,
  ListSubheader,
  Stack,
  styled,
  useTheme
} from '@mui/material';
import { useMemo } from 'react';
import { getRouteObject, useCurrentRoutePathHook } from './RouteFunctions';

import { NavigationListItem } from './NavigationListItem';
import { ExtendedElementedRoute } from './RouteTypes';

const LeftNavigationArea = styled(Stack)(({ theme }) => ({
  width: '200px',
  height: '100%',
  borderRight: `1px solid ${theme.zOptions.elements.divider}`,
  overflowY: 'auto',
  backgroundColor: theme.zOptions.elements.card.background.default
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.zOptions.elements.divider,
  width: '100%',
  marginTop: '10px'
}));

export const NavArea = (): JSX.Element => {
  const currentRoutePath = useCurrentRoutePathHook();

  const theme = useTheme();

  const route: ExtendedElementedRoute | undefined = useMemo(() => {
    //console.log('current path', currentRoutePath);

    // go through currentRoutePath back to front and return the route if it has subNav enabled
    for (let i = currentRoutePath.length - 1; i >= 0; i--) {
      const r = getRouteObject(currentRoutePath[i]);
      if (r?.subRouting) {
        return r;
      }
    }
    return undefined;
  }, [currentRoutePath]);

  // nothing to render for this route, no submenu
  if (route === undefined) {
    return <></>;
  }

  return (
    <LeftNavigationArea
      direction='column'
      alignContent={'center'}
      alignItems={'center'}
      spacing={1}
    >
      {route.children !== undefined &&
        route.children.map((child, index) => {
          if (child.hideFromMenu) {
            return undefined;
          }

          // for each child of our page that has subrouting enabled
          if (child.children === undefined) {
            return undefined;
          }

          // at least one of the children must be navigatable
          if (child.children.filter((c) => !c.hideFromMenu).length === 0) {
            return undefined;
          }

          // buid a list for subnav to those things
          return (
            <Box
              key={`subnav-${child.label}`}
              sx={{
                width: '100%'
              }}
            >
              <List
                key={`List-${
                  child.label ?? 'undef sidebar header' + child.routeID
                }`}
                dense
                subheader={
                  <ListSubheader
                    component='div'
                    id='nested-list-subheader'
                    sx={{
                      backgroundColor: 'inherit',
                      color: theme.zOptions.navigation.subheader,
                      paddingTop: '5px',
                    }}
                    disableSticky
                    color='primary'
                  >
                    {child.label ?? 'undef sidebar header' + child.routeID}
                  </ListSubheader>
                }
              >
                {child.children.map((subChild) => {
                  if (subChild.hideFromMenu) {
                    return undefined;
                  }

                  return (
                    <NavigationListItem
                      routeObject={subChild}
                      key={`${child.label}-subNav-${subChild.label}`}
                    />
                  );
                })}
              </List>

              {route.children && index < route.children.length - 1 && (
                <StyledDivider
                  key={`divider-${
                    child.label ?? 'undef sidebar header' + child.routeID
                  }`}
                />
              )}
            </Box>
          );
        })}
    </LeftNavigationArea>
  );
};
