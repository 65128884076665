import React from 'react';
import { Typography } from '@mui/material';
import { Construction } from '@mui/icons-material';

const PageUnderConstruction = (): JSX.Element => {
  return (
    <>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <Construction sx={{ fontSize: '15em', color: 'white' }} />
      </div>
      <div style={{ marginTop: '40px', width: '100%' }}>
        <Typography variant='h5' align='center'>
          Looks like you&apos;re an early visitor.
        </Typography>
      </div>
      <div style={{ marginTop: '15px', width: '100%' }}>
        <Typography variant='h5' align='center'>
          Unfortunately, this site is still under construction so please wear a
          helmet around here for safety reasons.
        </Typography>
      </div>
      <div style={{ marginTop: '15px', width: '100%' }}>
        <Typography variant='h5' align='center'>
          Hopefully you&apos;ll like what we did once we&apos;re finished.
        </Typography>
      </div>
    </>
  );
};

export default PageUnderConstruction;
