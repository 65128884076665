import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Collapse,
  List,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Dialog
} from '@mui/material';
import { ExpandLess, ExpandMore, Close, Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { NavigationListItem } from './NavigationListItem';
import {
  enabledRoutes,
  isRedirectionRoute,
  useCurrentRoutePathHook
} from './RouteFunctions';
import { ExtendedRoute } from './RouteTypes';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const StyledMenuIcon = styled(Menu)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginRight: '0px',
    marginLeft: '10px'
  },
  // [theme.breakpoints.up('sm')]: {
  //   marginRight: '15px',
  //   marginLeft: '20px'
  // }
}));

/**
 * Automatically nested root object based on ExtendedRoute
 * @param props
 * @returns
 */
const MenuEntry = (props: {
  routeObject: ExtendedRoute;
  setOpenMenu: (newVal: boolean) => void;
}): JSX.Element => {
  const currentRoutePath = useCurrentRoutePathHook();
  const [nestedMenuOpen, setNestedMenuOpen] = useState(false);

  // useffect if the current menu is in the root then it should be open
  useEffect(() => {
    if (isRedirectionRoute(props.routeObject)) {
      // redirection routes would instantly redirect anyways, they can't be 'open' and they never have children
      return;
    }

    if (currentRoutePath.includes(props.routeObject.routeID)) {
      setNestedMenuOpen(true);
    }
    // empty since we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.routeObject.hideFromMenu) {
    return <></>;
  }
  if (
    props.routeObject.children === undefined ||
    (props.routeObject.children as ExtendedRoute[]).filter(
      (child) => !child.hideFromMenu
    ).length === 0
  ) {
    return (
      <NavigationListItem
        routeObject={props.routeObject}
        onClick={(): void => {
          props.setOpenMenu(false);
        }}
        key={`RootSideNav-${props.routeObject.label}`}
      />
    );
  } else {
    // if it has children then recursively add them
    return (
      <>
        <NavigationListItem
          routeObject={props.routeObject}
          onClick={(): void => {
            setNestedMenuOpen(!nestedMenuOpen);
          }}
          disableNavigation
        >
          {nestedMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </NavigationListItem>

        <Collapse
          in={nestedMenuOpen}
          timeout='auto'
          unmountOnExit
          sx={{ paddingLeft: '10px' }}
        >
          {props.routeObject.children &&
            (props.routeObject.children as ExtendedRoute[]).map((child) => {
              return (
                <MenuEntry
                  routeObject={child}
                  setOpenMenu={props.setOpenMenu}
                  key={`RootSideNav-${child.label}`}
                />
              );
            })}
        </Collapse>
      </>
    );
  }

  return <></>;
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  paddingRight: '10px',
  paddingLeft: '10px',
  boxShadow: '0px 3px 3px 3px ' + theme.zOptions.header.backgroundColor,
  zIndex: 30,
  position: 'relative',
  overflow: 'hidden'
}));

export const SmallerScreenNavigation = (): JSX.Element => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (): void => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <IconButton
        color='primary'
        aria-label='navigation menu'
        onClick={handleClick}
      >
        <StyledMenuIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={openMenu}
        onClose={handleClick}
        TransitionComponent={Transition}
      >
        <StyledAppBar>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Menu
            </Typography>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClick}
              aria-label='close'
            >
              <Close />
            </IconButton>
          </Toolbar>
        </StyledAppBar>
        <List
          sx={{
            width: '100%',
            paddingRight: '10px',
            paddingLeft: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingTop: '10px'
          }}
          component='nav'
          dense
          aria-labelledby='navigation-list'
        >
          {/* Each root route creates one menu item, if it has children then subrouting is shown */}

          {enabledRoutes !== undefined &&
            (enabledRoutes.children as ExtendedRoute[]).map((groundRoute) => {
              if (groundRoute === undefined) {
                return <></>;
              } else {
                return (
                  <MenuEntry
                    routeObject={groundRoute}
                    setOpenMenu={setOpenMenu}
                    key={`"baseroute"+${groundRoute.label}-subNav-${groundRoute.label}`}
                  />
                );
              }
            })}
        </List>
      </Dialog>
    </>
  );
};
