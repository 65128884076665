import { Box, Stack, styled } from '@mui/material';
import Header from '../components/Header';
import { Outlet, Navigate } from 'react-router-dom';
import { RouteID } from './RouteIDs';
import { ExtendedRoute } from './RouteTypes';
import { NavArea } from './SidebarNavigator';
// import Admin from '../components/Admin';
import ServerList from '../components/ServerList';
import { ProtectedRoute } from './ProtectedRoute';
import { Login } from 'components/Login';
import { AccessRoles } from 'remote/AuthenticationStorage';

const MainContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',

  [theme.breakpoints.up('xs')]: {
    padding: '5px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px'
  },
  overflowY: 'auto',

  // here and not in theme, as MuiBox does not exist in the ThemeOptions
  // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
  userSelect: 'none',
  WebkitTapHighlightColor: 'transparent',
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',

  '& .MuiBox-focused': {
    outline: 'none !important'
  }
}));

const RootPage = (): JSX.Element => (
    <>
      <Stack
        id='Root-wrapper-column'
        direction='column'
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        sx={{
          width: '100vw',
          height: '100vh'
        }}
      >
        <Header />
        <Stack
          direction='row'
          id='Root-wrapper-row'
          justifyContent={'stretch'}
          alignItems={'stretch'}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <Box>
            <NavArea />
          </Box>
          <MainContentBox>
            <Outlet />
          </MainContentBox>
        </Stack>
      </Stack>
    </>
  );

// Base route object stitching from before together
// This should almost certainly not be used directly! use enabledRoutes from RouteFunctions.tsx instead
export const RootRoute: ExtendedRoute = {
  routeID: RouteID.RootDefaultRoute,
  label: 'Root',
  path: '/',
  element: <RootPage />,
  children: [
    {
      routeTo: RouteID.Server,
      index: true
    },
    {
      routeID: RouteID.Server,
      label: 'Server',
      path: 'server',
      element: <ProtectedRoute allowedRoles={[AccessRoles.ADMIN, AccessRoles.BASIC, AccessRoles.ADVANCED]} ><ServerList /></ProtectedRoute>
    },
    // {
    //   routeID: RouteID.Admin,
    //   label: 'Admin',
    //   path: 'admin',
    //   element: <ProtectedRoute allowedRoles={[AccessRoles.ADMIN]}><Admin /></ProtectedRoute>
    // },
    {
      routeID: RouteID.Login,
      label: 'Login',
      path: 'login',
      element: <Login />
    },
    {
      routeID: RouteID.CatchAllRoute,
      label: 'catch all',
      element: <Navigate to={'/'} />,
      path: '*',
      hideFromMenu: true
    }
  ]
};
