import { Logout, Password, Person } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { TUser, useAuthentication } from "remote/AuthenticationStorage";
import Client, { HttpError } from "remote/Client";
import { ChangePassword } from "./ChangePw";

export const LoginAvatar = (): JSX.Element => {
  const { updateToken, isAuthenticated, setUser, user } = useAuthentication();

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);
  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logout = useCallback(() => {
    updateToken(null);
    handleClose();
  }, [updateToken, handleClose]);

  useEffect(() => {
    if (isAuthenticated) {
      Client.getUser()
        ?.then((data: TUser) => setUser(data))
        .catch((e: HttpError) => {
          enqueueSnackbar(e.message, { variant: "error" });
          if (e.statusCode === 401) {
            updateToken(null);
          }
        });
    }
  }, [isAuthenticated, setUser, updateToken]);

  const [showChangePwDialog, setShowChangePwDialog] = useState<boolean>(false);

  const openChangePwDialog = useCallback(() => {
    setShowChangePwDialog(true);
  }, []);

  const closeChangePwDialog = useCallback(() => {
    setShowChangePwDialog(false);
  }, []);


  if (!isAuthenticated) {
    return (<></>);
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ ml: 2 }}
        aria-controls={showMenu ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={showMenu ? 'true' : undefined}
      >
        <Avatar sx={{ backgroundColor: theme.palette.secondary.light, width: 36, height: 36 }}>Z</Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={showMenu}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>
          <ListItemIcon>
            <Person fontSize='small' />
          </ListItemIcon>
          {user ? user.username : 'n/a'}
        </MenuItem>
        <MenuItem onClick={openChangePwDialog} divider>
        <ListItemIcon>
          <Password fontSize='small' />
        </ListItemIcon>
        Change Password
      </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <ChangePassword 
        showDialog={showChangePwDialog}
        closeDialog={closeChangePwDialog}
      />
    </>
  );
};