import { createTheme, Theme } from '@mui/material/styles';
import {
  PaletteMode,
  responsiveFontSizes,
  ThemeOptions,
  zOptions,
  zOptionSettings
} from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

// Separate file introduced in ZWC-57

// StartRegion
// special area for functions that the theme needs or are util
// Consider Moving this to zUtil Package

/**
 * Take 2 colors in string hex RGB format, return a color in string hex RGB format that is interpolated between the two
 *
 * @param value percentage value to interpolate, if it is a string its assumed to be "xxx%" with at least one digit before %
 * @returns
 */
const interpolateRGBColors = (
  col1: { r: number; g: number; b: number },
  col2: { r: number; g: number; b: number },
  value: number | string
): { r: number; g: number; b: number } => {
  // if value is a string then port it to a number first
  if (typeof value === 'string') {
    // regex to match X% | XX% | XXX%
    const checkDigit = '^[0-9]{1,3}%$';

    if (value.match(checkDigit)) {
      // remove the % sign
      // eslint-disable-next-line no-param-reassign
      value = Number(value.replace('%', ''));
    } else {
      console.warn('Invalid percentage value', value);
      throw new Error('Invalid percentage value');
    }
  }

  // clamp value between 0 and 100
  const percentage = Math.min(Math.max(value, 0), 100) / 100;

  const q = 1 - percentage;
  const r = Math.round(col1.r * percentage + col2.r * q);
  const g = Math.round(col1.g * percentage + col2.g * q);
  const b = Math.round(col1.b * percentage + col2.b * q);

  return { r, g, b };
};

// EndRegion

// Just our page options
// NOTICE: Why are these things not just string colors but main/dark/light structs?
//         -> Because its for future implementation. if we ever implement a light theme we might want to switch some page base colors as well.
//         -> doing this manually is an absolute pain, so we implement a function that switches the color according to the selected theme.
const zthemeOptions: zOptionSettings = {
  // page specific coloring
  visualizer: {
    sidebar: {
      batteryIcon: {
        mainColor: { main: '#ffffff' },
        alertColor: { main: '#da0000' },
        goodColor: { main: '#008a00' },
        warningColor: { main: '#fadb00' },
        unknownColor: { main: '#909090' }
      },
      tracker: {
        greenLightColor: { main: '#99CC00' },
        orangeLightColor: { main: '#FFBB33' },
        redLightColor: { main: '#FF4444' }
      },
      actor: {
        stateUnknownColor: { main: '#909090' }
      },
      anchor: {
        stateUnknownColor: { main: '#909090' }
      },
      fixture: {
        stateUnknownColor: { main: '#909090' }
      }
    },
    dialog: {
      acceptButtonBGColor: { main: '#008a00' },
      abortButtonBGColor: { main: '#da0000' },
      styledOKIconColor: { main: '#008a00' },
      styledErrorIconColor: { main: '#da0000' }
    },
  },
  header: {
    backgroundColor: {
      main: '#151515'
    },
    serverConnection: {
      offline: { main: '#dd2c00' },
      loading: { main: '#FF9C04' },
      online: { main: '#76ff03' }
    },
    systemState: {
      green: { main: '#2E9933' },
      red: { main: '#FF4444' },
      orange: { main: '#FF8800' },

      // color for background for the "M" and "B" when either is not available
      notAvailableColor: { main: '#808080' },
      connectionBarColor: { main: '#808080' },

      // text color for unavailable servers in the dialog
      notAvailableTextColor: { main: '#B0B0B0' }
    }
  },
  linkPages: {
    unknownColor: 'transparent', // n/a , - , etc
    diagnosticCardBackgroundColor: '#151515',

    background1: '#3e3e3e',
    background2n: '#222222',
    sortLabel: {
      active: {
        color: '#ffffff'
      },
      inactive: {
        color: '#808080'
      }
    },
    // before using these change in theme.d.ts
    anchorLink: {},
    trackerLink: {}
  },
  // functions used in the theme. This is mainly for simplicity as the theme is available when immediate styling
  util: {
    interpolateRGBColors: interpolateRGBColors,
    background: {
      highlight: 'rgba(255, 255, 255, 0.2)'
    }
  },
  elements: {
    card: {
      background: {
        default: '#4B4B4B',
        light: '#666666'
      }
    },
    divider: '#606060',
    actions: {
      default: '#666666'
    }
  },
  navigation: {
    itemHover: '#545454',
    subheader: '#CCCCCC'
  }
};

// based on: https://mui.com/material-ui/customization/dark-mode/#dark-mode-with-a-custom-palette
function parseOptionSettings(
  zSettings: zOptionSettings,
  mode: PaletteMode
): zOptions {
  // for each entry in the zthemeOptions object that is of typee SimplePaletteColorOptions.
  // use either main or dark/light depending on the mode

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseObject = (obj: any): zOptions => {
    // go through all properties
    for (const key in obj) {
      // if it has prop "main" we are at a color palette object
      if (obj[key].hasOwnProperty('main')) {
        // if we are in dark mode, use dark/light, if its not defined use main
        if (mode === 'dark') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          obj[key] = obj[key].dark ? obj[key].dark : obj[key].main;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          obj[key] = obj[key].light ? obj[key].light : obj[key].main;
        }
      } else if (typeof obj[key] === 'object') {
        parseObject(obj[key]);
      }
    }
    return obj as zOptions;
  };

  return parseObject(zSettings);
}

// general palette options
const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      mxs: 450,
      sm: 600,
      smLandscape: 800,
      md: 900,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
      light: '#f3f3f3',
      dark: '#a8a8a8'
    },
    secondary: {
      main: '#FF9C04',
      light: '#ffaf36',
      dark: '#b26d02',
    },
    background: {
      default: '#303030',
      paper: '#222222',
    },
    success: {
      main: '#008a00'
    },
    warning: {
      main: '#FF8800'
    },
    error: {
      // aka danger
      main: '#dd2c00'
    },
    unknown: {
      main: '#909090'
    },
    contrastThreshold: 4.5,
    tonalOffset: 0.4,
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    // disable all kinds of variants -> too much variance
    /*h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,

    subtitle2: undefined,

    body2: undefined,*/

    fontSize: 12,
    allVariants: {}
  },
  components: {
    // input
    MuiInputBase: {
      defaultProps: {}
    },
    MuiInputLabel: {
      defaultProps: {}
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        margin: 'none' // do not use dense here, it will break alignment with dependency on MuiFormControl
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.inputProps?.inputMode === 'numeric' && {
            '& .MuiInputBase-input': {
              //textAlign: 'right'
            }
          })
        })
      }
    },
    MuiFormControlLabel: {},

    // Buttons
    MuiButton: {
      defaultProps: {
        size: 'small',
        color: 'secondary',
        variant: 'contained'
      },
      styleOverrides: {
        // override contained style to give it some margin, for some reason same buttons next to one another will look horrible as there is no margin for this variant?
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            marginRight: '2px',
            marginLeft: '2px'
          }),
          whiteSpace: 'nowrap',

          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        })
      }
    },
    MuiButtonBase: {},

    MuiIconButton: {
      defaultProps: {
        size: 'small',
        style: {
          //fontSize: 14
        }
      },
      styleOverrides: {
        root: ({ _, theme }) => ({
          ':hover': {
            // backgroundColor: 'rgba(255, 255, 255, 0.2)',
            backgroundColor: theme.zOptions.util.background.highlight,
            cursor: 'pointer',
          },
          padding: 0,
          margin: '5px',
          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },

          borderRadius: '5px',
        })
      }
    },

    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
        color: 'secondary'
      }
    },

    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: '8px',
          paddingRight: '8px',

          ...(ownerState.selected && {
            // NOTE: for some reason the basic MUI CSS sets color, and BgColor
            // for the base .MuiSelected class property in this.
            // overwriting it here us using 'select':{} subprop also doesn't help
            // seems to be a base issue?
            color: 'black !important',
            backgroundColor: theme.palette.secondary.main + ' !important',
            ': hover': {
              backgroundColor: theme.palette.secondary.main + ' !important'
            },
            marginRight: '2px',
            marginLeft: '2px'
          }),

          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        })
      }
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        }
      }
    },

    MuiCard: {
      defaultProps: {
        sx: (theme) => ({
          backgroundColor: theme.zOptions.elements.card.background.default,

          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        })
      }
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        }
      }
    },

    MuiCheckbox: {
      defaultProps: {
        size: 'small',
        sx: (theme) => ({
          '&.Mui-checked': {
            color: theme.palette.secondary.main
          },

          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        })
      }
    },
    MuiFab: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
        color: 'secondary',
        sx: (theme) => ({
          '& .Mui-checked.Mui-disabled': {
            color: `${theme.palette.secondary.main} !important`,
            opacity: 0.45
          },
        })
      },
    },
    // Form
    MuiFormControl: {
      // this makes issues with MuiTextField if the styles do not match up their margins
      defaultProps: {
        size: 'small',
        margin: 'none' // do not use dense here, it will break MuiTextField alignment and internal padding
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense'
      }
    },
    // Icons
    MuiIcon: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingRight: '16px',
          paddingLeft: '16px',
          paddingTop: '16px',
          paddingBottom: '8px'
        }
      }
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingRight: '16px',
          paddingLeft: '16px',
          paddingBottom: '16px',
          paddingTop: '0px'
        }
      }
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingRight: '16px',
          paddingLeft: '16px',
          paddingBottom: '16px',
          paddingTop: '0px'
        }
      }
    },

    MuiRadio: {
      defaultProps: {
        size: 'small'
      }
    },

    // containers
    MuiGrid2: {
      defaultProps: {
        display: 'flex',
        alignItems: 'center'
      }
    },

    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '0px',
          paddingBottom: '0px',

          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        }
      }
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,

          // NOTE: hardcoded black since it ignores the theme colors in defaultProps
          backgroundColor: 'black'
        },
        arrow: {
          color: 'black'
        }
      },
    },

    // higher order components

    MuiToolbar: {
      defaultProps: {
        variant: 'dense'
      },
      styleOverrides: {}
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderStyle: 'none',
          
          // source: https://stackoverflow.com/a/21003770 https://stackoverflow.com/a/32100497
          userSelect: 'none',
          WebkitTapHighlightColor: 'transparent',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',

          ':focus': {
            outline: 'none !important'
          },
        },
        cell: {
          ':focus': {
            outline: 'none'
          }
        },
      },
      defaultProps: {
        slotProps: {
          toolbar: {
            // If we have a default toolbar component that we want to put in _every_ table
          }
        },
        sx: (theme) => ({
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 'initial'
          },
        })
      }
    }
  },
  spacing: 4,

  zOptions: parseOptionSettings(zthemeOptions, 'dark')
};

// Page to provide theming and import it, also dark/light switching.
// Create the main theme, depends on system information
const themeCreated: Theme = createTheme(themeOptions);

export const zTheme = responsiveFontSizes(themeCreated);
