import { ListItemButton, ListItemText, useTheme, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getToRoute, useCurrentRoutePathHook } from './RouteFunctions';
import { useMemo } from 'react';
import { RouteID } from './RouteIDs';
import { ExtendedRoute } from './RouteTypes';

type NavigationListItemProps = {
  routeObject: ExtendedRoute;
  noInset?: boolean;
  onClick?: () => void; // Fired BEFORE navigation, always fired even if disabledNavigation is set
  children?: JSX.Element | JSX.Element[]; // meant to be used for icons etc
  disableNavigation?: boolean;
};

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  ':hover': {
    // backgroundColor: theme.zOptions.navigation.itemHover
    backgroundColor: theme.zOptions.util.background.highlight
  },
  width: '100%'
}));

export const NavigationListItem = (
  props: NavigationListItemProps
): JSX.Element => {
  const currentRoutePath = useCurrentRoutePathHook();
  const theme = useTheme();
  const navigationHook = useNavigate();

  const selected = useMemo(() => {
    // if i am an index route and my parent is selected, i am selected
    if (
      props.routeObject.index &&
      props.routeObject.parentRouteID === currentRoutePath.at(-1)
    ) {
      return true;
    }

    if (props.routeObject.routeID === undefined) {
      console.error(
        'routeID is undefined for',
        props.routeObject,
        'meaning we cannot make a redirection button out of it'
      );
      return false;
    } else {
      return currentRoutePath.includes(props.routeObject.routeID);
    }

    // we only depend on this hook the others are uninteresting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoutePath]);

  return (
    <StyledListItemButton
      onClick={(_evt): void => {
        //console.log('nav to ', props.routeObject.routeID);
        if (props.onClick) {
          props.onClick();
        }
        if (!props.disableNavigation) {
          navigationHook(
            getToRoute(props.routeObject.routeID ?? RouteID.RootDefaultRoute)
          );
        }
      }}
    >
      <ListItemText
        primary={props.routeObject.label}
        primaryTypographyProps={{
          color: selected ? theme.palette.secondary.main : 'white',
          paddingLeft: props.noInset ? '0px' : '20px',
          fontSize: theme.typography.pxToRem(14),

          lineHeight: '1.3'
        }}
      />
      {props.children}
    </StyledListItemButton>
  );
};
