import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { RouteID, getToRoute } from 'navigation/Navigation';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from 'remote/AuthenticationStorage';
import Client, { HttpError } from 'remote/Client';
import { PasswordField } from './PasswordField';
import { TToken } from '../@types/DataTypes';

export const Login = (): JSX.Element => {
  const { isAuthenticated, updateToken } = useAuthentication();

  const usernameRef = useRef<string>('');
  const passwordRef = useRef<string>('');

  const [unauthorized, setUnauthorized] = useState<boolean>();

  const handleLoginData = useCallback(
    (token: TToken): void => {
      const { access_token } = token;
      updateToken(access_token);
      setUnauthorized(false);
    },
    [updateToken]
  );

  const loginCb = useCallback(() => {
    const username = usernameRef.current;
    const password = passwordRef.current;

    Client.login(username, password)
      .then(handleLoginData)
      .catch((e: HttpError) => {
        if (e.statusCode === 401) {
          enqueueSnackbar("Incorrect username or password", { variant: "error" });
        } else {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        setUnauthorized(true);
        updateToken(null);
      });
  }, [handleLoginData, updateToken]);

  if (isAuthenticated) {
    return <Navigate to={getToRoute(RouteID.Server)} />;
  }

  return (
    <>
      <Dialog 
        open={true}
      > 
        <DialogTitle variant='h5'>
          Log in
        </DialogTitle>
        <DialogContent>
          <TextField
            variant='outlined'
            type='text'
            label='Username'
            sx={{ marginTop: '30px', width: '100%' }}
            onChange={(e): void => {
              usernameRef.current = e.target.value;
            }}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                loginCb();
              }
            }}
            error={unauthorized}
          /><br />
          <PasswordField
            sx={{ marginTop: '30px' }}
            handleChange={(value: string): void => {
              passwordRef.current = value;
            }}
            handleEnterPressed={loginCb}
            error={unauthorized}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={loginCb}>Log in</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
