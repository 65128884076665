import styled from "@mui/material/styles/styled"
import { Typography } from '@mui/material';

export const StyledText = styled(Typography, {
  shouldForwardProp: (propFullName) =>
    propFullName !== 'textColor' && propFullName !== 'inactive'
})<{ textColor?: string; inactive?: boolean }>(({ textColor, inactive }) => ({
  textTransform: 'none',
  border: '0px',
  color: textColor !== undefined ? textColor : 'white',
  fontStyle: inactive ? 'italic' : 'normal',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  alignSelf: 'center',
  '& .MuiTypography-root': {
    height: 'inherit',
    display: 'flex',
    alignItems: 'center'
  }
}));
