import { Check, Close, Wifi, WifiOff } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  styled,
  Grid,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { StyledText } from './UtilityComponents';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Client, { HttpError } from '../remote/Client';
import {
  TypeServer,
  TypeServerList,
  TypeSystemTypeList
} from '../@types/DataTypes';
import { AccessRoles, useAuthentication } from 'remote/AuthenticationStorage';
import { enqueueSnackbar } from 'notistack';

const StyledConnected = styled(Wifi)(({ theme }) => ({
  color: theme.zOptions.visualizer.sidebar.tracker.greenLightColor
}));

const StyledNotConnected = styled(WifiOff)(({ theme }) => ({
  color: theme.zOptions.visualizer.sidebar.tracker.redLightColor
}));

const StyledCheck = styled(Check)(({ theme }) => ({
  color: theme.zOptions.visualizer.sidebar.tracker.greenLightColor
}));

const StyledCross = styled(Close)(({ theme }) => ({
  color: theme.zOptions.visualizer.sidebar.tracker.redLightColor
}));

const StyledResponsiveFlexSpace = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    flex: 0
  },
  [theme.breakpoints.up('lg')]: {
    flex: 1
  }
}));

const StyledFilterContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginBottom: '20px',
    marginTop: '0px',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '40px',
    marginTop: '20px',
  },
}));

const StyledDataContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex', 
    flexDirection: 'column',
    flex: 6
  }
}));

const StyledMainContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
  }
}));

const StyledTextField = styled(TextField)(() => ({
  margin: 'auto',
  width: '100%'
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px'
  }
}));

type ServerEntryToDisplay = {
  name: string;
  ip: string;
  connected: boolean;
  lastOnline?: string;
  createdAt?: string;
  systemType?: string;
  cname?: string;
  internal: boolean;
};

// source: https://stackoverflow.com/a/64574115
const ipToNumber = (ipAddress: string): number => {
  return ipAddress
    .split('.')
    .map((p) => parseInt(p))
    .reverse()
    .reduce((acc, val, i) => acc + val * 256 ** i, 0);
};

const columns: { [key: string]: GridColDef} = {
  'name': {
    field: 'name',
    headerName: 'Server Name',
    align: 'left',
    headerAlign: 'left',
    flex: 1.5,
    minWidth: 180,
    type: 'string',
    renderCell: (params): JSX.Element => {
      return <StyledText>{params.value}</StyledText>;
    }
  },
  'ip': {
    field: 'ip',
    headerName: 'IP Address',
    flex: 0.8,
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    type: 'string',
    renderCell: (params): JSX.Element => {
      return <StyledText>{params.value}</StyledText>;
    },
    sortComparator: (ip1: string, ip2: string): number => {
      return ipToNumber(ip1) - ipToNumber(ip2);
    }
  },
  'systemType': {
    field: 'systemType',
    headerName: 'System Type',
    flex: 0.8,
    minWidth: 110,
    align: 'left',
    headerAlign: 'left',
    type: 'string',
    renderCell: (params): JSX.Element => {
      return <StyledText>{params.value}</StyledText>;
    }
  },
  'cname': {
    field: 'cname',
    headerName: 'VPN Config',
    flex: 1.5,
    minWidth: 220,
    align: 'left',
    headerAlign: 'left',
    type: 'string',
    renderCell: (params): JSX.Element => {
      return <StyledText>{params.value}</StyledText>;
    }
  },
  'createdAt': {
    field: 'createdAt',
    headerName: 'First Seen',
    flex: 1.5,
    minWidth: 180,
    align: 'left',
    headerAlign: 'left',
    type: 'string',
    renderCell: (params): JSX.Element => {
      return <StyledText>{params.value}</StyledText>;
    }
  },
  'lastOnline': {
    field: 'lastOnline',
    headerName: 'Last Seen',
    flex: 1.2,
    minWidth: 180,
    align: 'left',
    headerAlign: 'left',
    type: 'string',
    renderCell: (params): JSX.Element => {
      return <StyledText>{params.value}</StyledText>;
    }
  },
  'internal': {
    field: 'internal',
    headerName: 'Internal',
    flex: 0.5,
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'boolean',
    renderCell: (params): JSX.Element => {
      let content;

      if (params.value) {
        content = <StyledCheck />;
      } else {
        content = <StyledCross />;
      }

      return <Box sx={{ margin: '0px' }}>{content}</Box>;
    }
  },
  'connected': {
    field: 'connected',
    headerName: 'Online',
    flex: 0.6,
    minWidth: 70,
    align: 'center',
    headerAlign: 'center',
    type: 'boolean',
    renderCell: (params): JSX.Element => {
      let content;

      if (params.value) {
        content = <StyledConnected />;
      } else {
        content = <StyledNotConnected />;
      }

      return <Box sx={{ margin: '0px' }}>{content}</Box>;
    }
  }
};

const ServerList = (): JSX.Element => {
  const [serverList, setServerList] = useState<TypeServerList>([]);
  const [systemTypes, setSystemTypes] = useState<TypeSystemTypeList>([]);

  const { user, updateToken } = useAuthentication();

  const pollData = useCallback(() => {
    Client.getServerList()
      ?.then((sl: TypeServerList) => {
        setServerList(sl);
      })
      .catch((e: HttpError) => {
        enqueueSnackbar(e.message, { variant: 'error' });
        if (e.statusCode === 401) {
          updateToken(null);
        }
      });
  }, [updateToken]);

  useEffect(() => {
    Client.getSystemTypeList()
      ?.then((stl: TypeSystemTypeList) => {
        setSystemTypes(stl);
      })
      .catch((e: HttpError) => {
        enqueueSnackbar(e.message, { variant: 'error' });
        if (e.statusCode === 401) {
          updateToken(null);
        }
      });

    pollData();

    const intervalId = setInterval(pollData, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [pollData, updateToken]);

  const [nameFilter, setNameFilter] = useState<string>('');
  const [ipFilter, setIpFilter] = useState<string>('');
  const [connectedFilter, setConnectedFilter] = useState<boolean>(true);
  const [systemTypeFilter, setSystemTypeFilter] = useState<string>('');
  const [internalFilter, setInternalFilter] = useState<boolean>(false);

  const rows: ServerEntryToDisplay[] = useMemo(() => {
    return serverList
      .filter(
        (s) =>
          nameFilter === '' ||
          s.name?.toLowerCase().includes(nameFilter.toLowerCase())
      )
      .filter(
        (s) =>
          ipFilter === '' ||
          s.ip?.toLowerCase().includes(ipFilter.toLowerCase())
      )
      .filter(
        (s) => connectedFilter === false || s.connected === connectedFilter
      )
      .filter(
        (s) =>
          systemTypeFilter === '' ||
          s.system_type?.toUpperCase() === systemTypeFilter
      )
      .filter((s) => internalFilter === false || s.internal === internalFilter)
      .map((s: TypeServer): ServerEntryToDisplay => {
        return {
          name: s.name || 'n/a',
          ip: s.ip || 'n/a',
          connected: s.connected || false,
          systemType: s.system_type,
          lastOnline: s.last_seen_str,
          createdAt: s.created_at_str,
          cname: s.cname,
          internal: s.internal || false
        };
      });
  }, [
    serverList,
    ipFilter,
    nameFilter,
    connectedFilter,
    systemTypeFilter,
    internalFilter
  ]);

  const theme = useTheme();
  const isBiggerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const cols: GridColDef[] = useMemo(() => {
    const c = [];

    if (isBiggerThanMd) {
      c.push(columns['name']);
      c.push(columns['ip']);
      c.push(columns['systemType']);
      c.push(columns['cname']);
      c.push(columns['createdAt']);
      c.push(columns['lastOnline']);
      c.push(columns['internal']);
      c.push(columns['connected']);
    } else {
      c.push(columns['name']);
      c.push(columns['ip']);
      c.push(columns['connected']);
      c.push(columns['systemType']);
      c.push(columns['internal']);
      c.push(columns['lastOnline']);
      c.push(columns['cname']);
      c.push(columns['createdAt']);
    }

    return c;
  }, [isBiggerThanMd]);

  const filterArea = useMemo(() => {
    const useInternalFilter =
      user?.role === AccessRoles.ADMIN || user?.role === AccessRoles.ADVANCED;
    const filterNr = useInternalFilter ? 5 : 4;
    return (
      <StyledFilterContainer container spacing={4}>
        <Grid item xs={6} md={12 / filterNr}>
          <StyledTextField
            placeholder='Filter Name'
            onChange={(e): void => setNameFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={12 / filterNr}>
          <StyledTextField
            placeholder='Filter IP Address'
            onChange={(e): void => setIpFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={12 / filterNr}>
          <FormControl sx={{ margin: 'auto', width: '100%' }}>
            <InputLabel>System Type</InputLabel>
            <Select
              value={systemTypeFilter}
              label='System Type'
              onChange={(e): void => setSystemTypeFilter(e.target.value)}
            >
              <MenuItem value=''>
                <i>-- All --</i>
              </MenuItem>
              {systemTypes.map((systemType) => (
                <MenuItem value={systemType.name} key={systemType.uuid}>
                  {systemType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={12 / filterNr}>
          <FormControl sx={{ margin: 'auto', width: '100%', height: '100%' }}>
            <StyledFormControlLabel
              value={connectedFilter}
              label='Connected Only'
              control={
                <Switch
                  color='secondary'
                  checked={connectedFilter}
                  onChange={(e): void => setConnectedFilter(e.target.checked)}
                />
              }
            />
          </FormControl>
        </Grid>
        {useInternalFilter && (
          <Grid item xs={6} md={12 / filterNr}>
            <FormControl sx={{ margin: 'auto', width: '100%', height: '100%' }}>
              <StyledFormControlLabel
                value={internalFilter}
                label='Internal Only'
                control={
                  <Switch
                    color='secondary'
                    checked={internalFilter}
                    onChange={(e): void => setInternalFilter(e.target.checked)}
                  />
                }
              />
            </FormControl>
          </Grid>
        )}
      </StyledFilterContainer>
    );
  }, [systemTypes, systemTypeFilter, connectedFilter, user, internalFilter]);

  return (
    <StyledMainContainer>
      <StyledResponsiveFlexSpace />

      <StyledDataContainer>
        {filterArea}
        <DataGrid
          autoHeight
          disableColumnSelector
          hideFooterSelectedRowCount
          columns={cols}
          rows={rows}
          density='compact'
          rowSelection={false}
          getRowId={(row): string => row.ip}
          pagination
          disableColumnFilter
          columnVisibilityModel={{
            cname: false,
            createdAt: false,
            internal:
              user?.role === AccessRoles.ADMIN ||
              user?.role === AccessRoles.ADVANCED
          }}
          localeText={{ noRowsLabel: 'No server' }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'ip', sort: 'asc' }]
            }
          }}
        />
      </StyledDataContainer>
      <StyledResponsiveFlexSpace />
    </StyledMainContainer>
  );
};

export default ServerList;
