import { create } from "zustand";
import { storage } from "util/Storage";

export enum AccessRoles {
  ADMIN = "ADMIN",
  BASIC = "BASIC",
  ADVANCED = "ADVANCED"
}

export type TUser = {
  username: string,
  email: string,
  role: AccessRoles,
}

export type TAuthStore = {
  token: string | null,
  isAuthenticated: boolean,
  user?: TUser,
  updateToken: (new_token: string | null) => void,
  setUser: (new_user: TUser) => void
};

export const useAuthentication = create<TAuthStore>((set) => ({
  token: storage.accessToken,
  isAuthenticated: storage.isAuthenticated,

  updateToken(new_token: string | null): void {
    storage.updateToken(new_token);

    set({
      token: storage.accessToken,
      isAuthenticated: storage.isAuthenticated
    })
  },

  setUser(new_user: TUser): void {
    set({ user: new_user })
  }
}));