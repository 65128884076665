import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useAuthentication } from 'remote/AuthenticationStorage';
import { PasswordField } from './PasswordField';
import Client from 'remote/Client';
import { enqueueSnackbar } from 'notistack';
import { StyledText } from './UtilityComponents';


const StyledGridCell = styled(Grid)(() => ({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center'
}));

const pwRegex = /\S{8,}/

export type TChangePasswordDialog = {
  showDialog: boolean,
  closeDialog: () => void
};

export const ChangePassword = (props: TChangePasswordDialog): JSX.Element => {
  const { user } = useAuthentication();

  const { closeDialog, showDialog } = props;

  const oldPasswordRef = useRef<string>('');
  const newPasswordRef = useRef<string>('');
  const newPassword2Ref = useRef<string>('');

  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [oldPasswordError, setOldPasswordError] = useState<boolean>(false);

  const validateNewPassword = useCallback((): boolean => {
    if (newPassword2Ref.current !== newPasswordRef.current) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
      setOldPasswordError(false);
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      return false;
    }

    if (!newPasswordRef.current.match(pwRegex)) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
      setOldPasswordError(false);
      enqueueSnackbar("New password must be at least 8 characters long", { variant: "error" });
      return false;
    }

    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setOldPasswordError(false);

    return true;
  }, []);

  const handleClose = useCallback(() => {
    setConfirmPasswordError(false);
    setNewPasswordError(false);
    setOldPasswordError(false);

    closeDialog();
  }, [closeDialog]);

  const changePassword = useCallback(() => {
    if (validateNewPassword()) {
      Client.changePassword({
        old_pw: oldPasswordRef.current,
        password: newPasswordRef.current,
        username: user?.username || ''
      })?.then(() => {
        handleClose();
        enqueueSnackbar("Password changed successfully", { variant: "success" });
      }).catch((e) => {
        setConfirmPasswordError(true);
        setNewPasswordError(true);
        setOldPasswordError(true);
        enqueueSnackbar(e.message, { variant: "error" });
      });
    }
  }, [handleClose, validateNewPassword, user]);

  const abort = useCallback(() => {
    oldPasswordRef.current = '';
    newPasswordRef.current = '';
    newPassword2Ref.current = '';

    handleClose();
  }, [handleClose]);

  return (
    <>
      <Dialog 
        open={showDialog}
        onClose={closeDialog}
      >      
        <DialogTitle variant='h5'>
          Change Password
        </DialogTitle>
        <DialogContent>
          <Grid spacing={2} container>
            <StyledGridCell item xs={5}>
              <StyledText>
                Current password:
              </StyledText>
            </StyledGridCell>
            <StyledGridCell item xs={7}>
              <PasswordField
                handleChange={(value: string): void => { oldPasswordRef.current = value }}
                handleEnterPressed={changePassword}
                error={oldPasswordError}
              />
            </StyledGridCell>
          </Grid>

          <Grid spacing={2} container>
            <StyledGridCell item xs={5}>
              <StyledText>
                New password:
              </StyledText>
            </StyledGridCell>
            <StyledGridCell item xs={7}>
              <PasswordField
                handleChange={(value: string): void => { newPasswordRef.current = value }}
                handleEnterPressed={changePassword}
                error={newPasswordError}
              />
            </StyledGridCell>
          </Grid>

          <Grid spacing={2} container>
            <StyledGridCell item xs={5}>
              <StyledText>
                Confirm new password:
              </StyledText>
            </StyledGridCell>
            <StyledGridCell item xs={7}>
              <PasswordField
                handleChange={(value: string): void => { newPassword2Ref.current = value }}
                handleEnterPressed={changePassword}
                error={confirmPasswordError}
              />
            </StyledGridCell>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={abort} variant='outlined'>
            Abort
          </Button>
          <Button color='primary' onClick={changePassword}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
