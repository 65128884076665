import {
  createBrowserRouter,
  Navigate,
  To,
  useLocation
} from 'react-router-dom';

import { useEffect, useState } from 'react';
import PageUnderConstruction from '../components/PageUnderConstruction';
import { getIncrementiveRedirectionRouteID, RouteID } from './RouteIDs';
import { ExtendedReRoute, ExtendedRoute } from './RouteTypes';
import { RootRoute } from './Routes';
import { Box } from '@mui/material';

export function isRedirectionRoute(
  route: ExtendedRoute
): route is ExtendedReRoute {
  return (
    route.routeID === RouteID.RedirectionRoute ||
    route.routeID === undefined ||
    route.routeID > RouteID.MaxRouteID
  );
}

/**
 *
 * @param routeId
 * @returns
 */
export const getRouteObject = (routeId: RouteID): ExtendedRoute | undefined => {
  // Go through the routes tree and get the route element for the route ID, return undefined if it can't be found

  const search = (route: ExtendedRoute): ExtendedRoute | undefined => {
    if (route.routeID === routeId) {
      // should never happen
      return route;
    }

    if (route.children !== undefined) {
      for (const child of route.children) {
        const found = search(child as ExtendedRoute);
        if (found !== undefined) {
          // if something was found we can stop
          return found;
        }
      }
    }

    return undefined;
  };

  return search(RootRoute);
};

/**
 * Get the Absolute representation of the path (as a string).
 * 'To' is a string type compatible with <Navigate>
 *
 * @param routeId the route ID to get the path for
 * @returns string representation but in a To type
 */
export const getToRoute = (routeId: RouteID): To => {
  // Go through the routes tree and get the path to the route ID element, throw an error if it can't be found

  // recursive search and stitch together the matching path
  const getPath = (
    route: ExtendedRoute,
    untilNow?: string
  ): string | undefined => {
    if (
      route.routeID === routeId &&
      route.routeID !== undefined &&
      route.routeID !== RouteID.RedirectionRoute
    ) {
      if (route.path === undefined) {
        // the only way this is OK is if its an index route and the parent route is then the route we need
        if (route.index === true && route.parentRouteID !== undefined) {
          const parentRoute = getRouteObject(route.parentRouteID);
          if (parentRoute !== undefined) {
            return '';
          }
        }

        // every route should have a defined path
        console.warn(
          'route.path is undefined for route: ' +
            route.label +
            ' which almost guarantees an error'
        );
        return undefined;
      }

      return route.path;
    }

    if (route.children) {
      // classically go through all children and search
      for (const child of route.children) {
        const innerPath = getPath(
          child as ExtendedRoute,
          untilNow ? untilNow + route.path + '/' : route.path
        );

        if (innerPath !== undefined) {
          if (untilNow === undefined) {
            // we are root level
            return route.path + innerPath;
          } else {
            return route.path + '/' + innerPath;
          }
        }
      }
    }
    return undefined;
  };

  const path = getPath(RootRoute);
  if (path !== undefined) {
    return path;
  }

  console.warn('could not find path for route ', routeId);

  return '';
};

/**
 * Reverse of getToRoute, get the route ID from the absolute path. The path is split on / and the last most matching part is returned
 *
 *
 * @param absolutePath path starting with / to the current page
 * @returns RouteID of the _closest_ page
 */
export const getRouteIdFromRoute = (absolutePath: string): RouteID => {
  //  rip the route apart it should look somewhat like /live/anchor-editor/anchorDetails/1
  // in this case we would want to return RouteId.SetupAnchorEditorDetails, so the last most matching part

  if (!absolutePath.startsWith('/')) {
    console.warn('absolute path does not start with / ', absolutePath);
    return RouteID.RootDefaultRoute;
  }

  if (absolutePath.length === 1) {
    return RouteID.RootDefaultRoute;
  }

  const parts = absolutePath.split('/');
  parts[0] = '/'; // split eats the first / for root

  let maxDepthFound = 0;
  let maxDepthID = RouteID.RootDefaultRoute;

  const search = (route: ExtendedRoute, depth: number = 0): undefined => {
    if (route.path === undefined || isRedirectionRoute(route)) {
      // should never happen
      return undefined;
    }

    if (route.path === parts[depth]) {
      if (depth > maxDepthFound) {
        maxDepthFound = depth;
        maxDepthID = route.routeID;

        if (depth === parts.length - 1) {
          // if we are at the end of the array return immediately to not waste computation
          return;
        }
      }
      if (route.children !== undefined) {
        for (const child of route.children) {
          const found = search(child, depth + 1);
          if (found !== undefined) {
            // if something was found we can stop
            return;
          }
        }
      }
    }

    return;
  };

  search(RootRoute);

  return maxDepthID;
};

/**
 * Hook to get the current route ID
 * @returns
 */
export function useCurrentRouteHook(): RouteID {
  const loc = useLocation();

  const [value, setValue] = useState<RouteID>(
    getRouteIdFromRoute(loc.pathname)
  );

  useEffect(() => {
    // only update if the path actually changes
    const newRoute = getRouteIdFromRoute(loc.pathname);
    if (newRoute !== value) {
      setValue(newRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc]);

  return value;
}

/**
 *
 * @param routeId
 * @returns
 */
export const getRoutePath = (routeId: RouteID): RouteID[] => {
  if (routeId === RouteID.RedirectionRoute) {
    console.warn(
      'getRoutePath called with redirection route, these have multiple instances the result will certainly be wrong'
    );
    return [];
  }

  // get the route object, append to return array until parent is undefined
  const routePath: RouteID[] = [];
  let currentRoute = getRouteObject(routeId);

  while (currentRoute !== undefined) {
    if (isRedirectionRoute(currentRoute)) {
      console.warn(
        "Route path tried to calculate the path through a redirection object, which doesn't work, a redirection shouldn't have children"
      );
      return routePath;
    }

    routePath.push(currentRoute.routeID);
    currentRoute = getRouteObject(currentRoute.parentRouteID as RouteID);
  }
  routePath.reverse();

  return routePath;
};

/**
 * This does:
 * - Filter out disabled routes
 * - add parentIDs to all routes
 *
 * @param route
 * @returns
 */
const parseRoutes = (
  route: ExtendedRoute | undefined
): ExtendedRoute | undefined => {
  if (route === undefined) {
    return undefined;
  }
  if (route.path) {
    if (route.path !== '/' && route.path.includes('/')) {
      console.warn(
        'route.path includes /, this will break the pathfinder and resolver, split your routes up',
        route.path
      );
    }
  }

  if (route.disabled) {
    route.children = undefined;
    return undefined;
  }

  // recusively filter children
  if (route.children) {
    route.children = (route.children as ExtendedRoute[])
      .map(parseRoutes)
      .filter((child) => child && !child.disabled) as ExtendedRoute[];

    // add parent ID to all children
    route.children.forEach((child) => {
      child.parentRouteID = route.routeID;
    });
  } else {
  }
  return route;
};

/**
 * Helper function
 */
export const isRouteEnabled = (route: RouteID): boolean => {
  const obj = getRouteObject(route);
  if (obj === undefined) {
    return false;
  } else {
    return !obj.disabled;
  }
};

/**
 * Apply WrapperNavigateTo and WrapperPage to all routes
 * @param route
 * @returns
 */
const wrapElements = (route: ExtendedRoute | undefined): void => {
  if (route === undefined) {
    console.log('route is undefined ');
    return;
  }

  // wrap the element if it exists

  if (route.children !== undefined) {
    route.children.forEach(wrapElements);
  } else {
    // check if we are a reroute route, if so wrap in a navigate to

    // TODO maybe clean this up a bit so its a bit more clear what is happening, in general it tries its best to interpret redirectionroutes
    if (isRedirectionRoute(route)) {
      // give it a routeID so we do not break navigation
      route.routeID =
        getIncrementiveRedirectionRouteID() as RouteID.RedirectionRoute;

      if (!isRouteEnabled(route.routeTo)) {
        console.warn(
          `ReRoute Object (incrementative id: ${route.routeID}) to RouteID: ${route.routeTo} cannot render Navigation element. Destination route disabled`
        );
        route.element = (
          <Box>
            ReRoute Object (incrementative id: {route.routeID}) to RouteID:
            {route.routeTo}
            cannot render Navigation element. Destination route disabled.
          </Box>
        );
      } else {
        route.element = <Navigate to={getToRoute(route.routeTo)} />;
      }

      // if a redirection route does not have a path then it must be index route and it also must be hidden from a menu
      if (route.path === undefined) {
        if (route.index !== true) {
          // we need to give it a path or else it will break on navigation
          route.path = 'redirection-route-' + route.routeID;
        }

        if (route.label === undefined) {
          // if therer is no label we can't really show it
          route.hideFromMenu = true;
        }
      } else {
        if (route.label === undefined && route.hideFromMenu !== true) {
          console.warn(
            'route.label is undefined for redirection route: ',
            route,
            ' but path is defined (and not hidefrommenu)? so navigation should be possible ?'
          );

          route.label = 'redirection-route-' + route.routeID;
        }
      }
    }

    if (!route.element) {
      route.element = <PageUnderConstruction />;
    }
  }
};

export let enabledRoutes: ExtendedRoute | undefined = undefined;

/**
 * Get a browser router that defines every route in our system
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getBrowserRouter = () => {
  // filter out disabled routes
  if (enabledRoutes === undefined) {
    enabledRoutes = parseRoutes(RootRoute);

    if (enabledRoutes === undefined) {
      console.warn('base routes query returned undefined');
      return createBrowserRouter([]);
    }

    // Wrap all elements in a page wrapper
    wrapElements(enabledRoutes);
  }

  return createBrowserRouter([enabledRoutes]);
};

/**
 * Hook for current complete path
 * @returns
 */
export function useCurrentRoutePathHook(): RouteID[] {
  const currentRouteID = useCurrentRouteHook();

  const [value, setValue] = useState<RouteID[]>(getRoutePath(currentRouteID));

  useEffect(() => {
    setValue(getRoutePath(currentRouteID));
  }, [currentRouteID]);

  return value;
}
