import {
  StyledEngineProvider,
  ThemeProvider,
  CssBaseline
} from '@mui/material';
import { zTheme } from './theme';

import { getBrowserRouter } from './navigation/Navigation';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

function App(): JSX.Element {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={zTheme}>
        {/* <Stats /> */}
        <CssBaseline />
        {/*CssBaseline required for background https://mui.com/material-ui/customization/dark-mode/#dark-mode-by-default */}

        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />

        <RouterProvider router={getBrowserRouter()} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
