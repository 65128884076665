// Route ID
// Centralize definition of all existing routes in the entire project
export enum RouteID {
  // System routes
  // Non Navigateable
  CatchAllRoute = -2,
  RedirectionRoute = -1, // will be given a random ID above this range

  // actual nodes
  RootDefaultRoute = 0,

  Server,

  Admin,

  Login,

  // keep last
  MaxRouteID // DO NOT USE DIRECTLY
}

let incremental_ID = RouteID.MaxRouteID + 1;

export function getIncrementiveRedirectionRouteID(): RouteID {
  // ++ returns the number BEFORE incrementing

  return incremental_ID++;
}
