// based on: https://github.com/joonas-yoon/fastapi-react-oauth2/tree/main

import { Navigate, useLocation } from 'react-router-dom';

import { AccessRoles, useAuthentication } from '../remote/AuthenticationStorage';
import { RouteID, getToRoute } from 'navigation/Navigation';


export const ProtectedRoute = (props: { children: JSX.Element, allowedRoles: AccessRoles[] }): JSX.Element => {
  const { isAuthenticated, user } = useAuthentication();
  const loc = useLocation();

  const { children, allowedRoles } = props;

  if (!isAuthenticated) {
    return <Navigate to={getToRoute(RouteID.Login)} replace state={{ from: loc }} />;
  }

  if (user?.role && allowedRoles.includes(user?.role)) {
    return children;
  }

  return children;
};