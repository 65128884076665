import { VisibilityOff, Visibility } from "@mui/icons-material";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, SxProps } from "@mui/material";
import { useState } from "react";

export type PasswordFieldProps = {
  sx?: SxProps,
  handleEnterPressed: () => void,
  handleChange: (currentPassword: string) => void,
  error?: boolean
};

export const PasswordField = (props: PasswordFieldProps): JSX.Element => {

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
  };

  const { sx, handleChange, handleEnterPressed, error } = props;

  return (
    <FormControl variant='outlined' sx={sx}>
      <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
      <OutlinedInput
        id='outlined-adornment-password'
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label='Password'
        onChange={(e): void => {
          handleChange(e.target.value);
        }}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            handleEnterPressed();
          }
        }}
        error={error}
      />
    </FormControl>
  )
};